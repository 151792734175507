import type { IconId } from '@/shared/components/Icon'
import type { RichTextDocument } from '@/shared/components/RichText'
import type {
  ItemStatus,
  Maybe,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import type {
  EnrichedAvailableAddon,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import type { SelectedAddons } from '@/shared/store/slices/selectedContractSlice'

export const ADDON_TYPE = {
  hourlyMeasurement: 'hourlyMeasurement',
}

export const isHourlyMeasurementAddon = (addonType: string) =>
  addonType === ADDON_TYPE.hourlyMeasurement

/**
 * Method that filter out all addons that are not available under upgrade flow
 */
export const filterOutSpecialAddons = (addons: EnrichedAvailableAddon[]) =>
  addons.filter((addon) => !isHourlyMeasurementAddon(addon.addonType))

export const filterOnlySpecialAddons = (addons: EnrichedAvailableAddon[]) =>
  addons.filter((addon) => isHourlyMeasurementAddon(addon.addonType))

export type AddonItem = {
  /**
   * Addon tariffNo.
   */
  tariffNo: number
  /**
   * Addon templateNo.
   */
  templateNo?: number | null
  /**
   * Addon status.
   */
  status?: ItemStatus
  /**
   * In most cases addon name.
   */
  primaryText: string
  /**
   * Price information.
   */
  secondaryText: string
  /**
   * Additional information as richText
   */
  additionalInformation?: RichTextDocument | null
  /**
   * Description as richText
   */
  description?: string | null
  /**
   * Selling points as richText
   */
  sellingPoints?: (string | null)[] | null
  /**
   * Addon icon.
   */
  icon: IconId
  /**
   * If true addon is bundled into contract.
   */
  isBundled: boolean
  /**
   * Set to true to indicate that addon should be handled as hourly measurement checkbox.
   */
  isHourlyMeasurementAddon: boolean
  /**
   * Addon type.
   */
  addonType: string
  urlTerms?: string
  urlMoreInfo?: string | null
  /**
   * used in logged-in to indicate that addon is ordered until processed by backend
   */
  isOrdered?: boolean
}

const getAddonItem = (
  addon?: Pick<
    EnrichedAvailableAddon,
    'tariffElements' | 'cfData' | 'tariffNo' | 'templateNo' | 'addonType'
  >,
  isVatIncl?: boolean,
): AddonItem | null =>
  addon?.tariffNo
    ? {
        icon: addon.cfData.icon as IconId,
        primaryText: addon.cfData.title || '',
        secondaryText: getOneLinePriceFromTariffElements(addon.tariffElements, isVatIncl),
        additionalInformation: addon.cfData.additionalInformation,
        isBundled: !Boolean(addon.templateNo),
        tariffNo: addon.tariffNo,
        isHourlyMeasurementAddon: isHourlyMeasurementAddon(addon.addonType) ?? false,
        addonType: addon.addonType,
      }
    : null

const isAddonItem = (item: unknown): item is AddonItem => Boolean((item as AddonItem).tariffNo)

const getOneLinePriceFromTariffElements = (
  tariffElements?: Maybe<TariffElement[]>,
  isVatIncl?: boolean,
) =>
  tariffElements
    ?.map(
      (tariffEl) =>
        `${isVatIncl ? tariffEl.prices?.[0]?.priceInclVat : tariffEl.prices?.[0]?.priceExclVat} ${tariffEl.priceUnit}`,
    )
    .join(' + ') || ''

const shouldBeShown = (addon: AddonItem) => addon.isHourlyMeasurementAddon || addon.isBundled

export const getBundledOrPreselectedAddons = (contractTemplate?: EnrichedContractTemplate) =>
  contractTemplate
    ? [...contractTemplate.availableAddons, ...contractTemplate.bundledAddons]
        .map((addon) => getAddonItem(addon, contractTemplate.customerType === 'ENTERPRISE'))
        .filter(isAddonItem)
        .filter(shouldBeShown)
    : []

export const areAddonsSelected = (
  contractAddons: EnrichedAvailableAddon[],
  selectedAddons: SelectedAddons,
) => {
  const filteredAddons = filterOutSpecialAddons(contractAddons)
  return filteredAddons.every((addon) => Boolean(selectedAddons[addon.addonType]))
}
